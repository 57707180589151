/* style.css */

.projects {
    text-align: center;
    padding: 20px;
  }
  
  .slider {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 250px;
  }
  
  .project {
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
  }
  
  .project-navigation {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }
  
  .arrow {
    font-size: 24px;
    cursor: pointer;
    color: #FFFF8F; /* Arrow color */
    background: transparent;
    border: none;
    padding: 10px;
    transition: color 0.3s, transform 0.3s;
    display: flex;
    align-items: center;
  }
  
  .arrow.left:before {
    content: '◄'; /* Custom left arrow symbol */
  }
  
  .arrow.right:before {
    content: '►'; /* Custom right arrow symbol */
  }
  
  .arrow:hover {
    color: #FCF55F; /* Arrow color on hover */
    transform: scale(1.1);
  }
  
  .btn {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #FFFF8F;
    color: #8B8000;
    text-decoration: none;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .btn:hover {
    background-color: #FCF55F;
  }
  