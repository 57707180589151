.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Monda&display=swap');
*,
*::before,
*::after {
    box-sizing: border-box;
}

/* Custom Properties, update these for your own design */

:root {
    --ff-primary: 'IBM Plex Mono';
    --ff-secondary: 'Monda', monospace;
    
    --fw-reg: 300;
    --fw-bold: 900;
    
    --clr-light: #EEE9DA;
    --clr-dark: #153462;
    --clr-accent: #BAD1C2;
    
    --fs-h1: 3rem;
    --fs-h2: 2.25rem;
    --fs-h3: 1.25rem;
    --fs-body: 1rem;
    
    --bs: 0.25em 0.25em 0.75em rgba(0,0,0,.25),
          0.125em 0.125em 0.25em rgba(0,0,0,.15);
}

@media (min-width: 800px) {
    :root {
        --fs-h1: 4.5rem;
        --fs-h2: 3.75rem;
        --fs-h3: 1.5rem;
        --fs-body: 1.125rem;
    }
}

/* General styles */

body {
    background: var(--clr-light);
    color: var(--clr-dark);
    margin: 0;
    font-family: var(--ff-primary);
    font-size: var(--fs-body);
    line-height: 1.6;
}

section {
    padding: 5em 2em;
}

img {
    display: no-repeat ;
    max-width: 100%;
}

strong { font-weight: var(--fw-bold) }

:focus {
    outline: 3px solid var(--clr-accent);
    outline-offset: 3px;
}

/* Buttons */

.btn {
    display: inline-block;
    padding: .5em 2.5em;
    background: var(--clr-accent);
    color: var(--clr-dark);
    text-decoration: none;
    cursor: pointer;
    font-size: .8rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: var(--fw-bold);
    transition: transform 200ms ease-in-out;
}

.btn:hover {
    transform: scale(1.1);
    background-color: '6096B4';
    color: 'red';

}

/* Typography */

h1,
h2,
h3 {
    line-height: 1;
    margin: 0;
}

h1 { font-size: var(--fs-h1) }
h2 { font-size: var(--fs-h2) }
h3 { font-size: var(--fs-h3) }


.section__title {
    margin-bottom: .25em;
}

.section__title--intro {
    font-weight: var(--fw-reg);
}

.section__title--intro strong {
    display: block;
}

.section__subtitle {
    margin: 0;
    font-size: var(--fs-h3);
}

.section__subtitle--intro,
.section__subtitle--about {
    background: var(--clr-accent);
    padding: .25em 1em;
    font-family: var(--ff-secondary);
    margin-bottom: 1em;
}

.section__subtitle--work {
    color: var(--clr-accent);
    font-weight: var(--fw-bold);
    margin-bottom: 2em;
}

/* header */








/*  Intro section  */

.intro {
    position: relative;
}

.intro__img {
    box-shadow: var(--bs);
}

.section__subtitle--intro {
    display: inline-block;
}

@media (min-width: 600px) {
    .intro {
        display: grid;
        width: min-content;
        margin: 0 auto;
        grid-column-gap: 1em;
        grid-template-areas: 
        
            "img title"
            "img subtitle";
        grid-template-columns: min-content max-content;
    }
    
    .intro__img {
        grid-area: img;
        min-width: 250px;
        position: relative;
        z-index: 2;
    }    
    
    .section__subtitle--intro {
        align-self: start;
        grid-column: -1 / 1;
        grid-row: 2;
        text-align: right;
        position: relative;
        left: -1.5em;
        width: calc(100% + 1.5em);
    }
}



/*  My services section  */

.projects {
    background-color: var(--clr-dark);
    background-size: cover;
    background-blend-mode: multiply; 
    color: var(--clr-light);
    text-align: center;
}

.section__title--projects {
    color: 'white';
    position: relative;
}

.section__title--projects:after {
    content: '';
    display: block;
    width: 2em;
    height: 1px;
    margin: 0.5em auto 1em;
    background: var(--clr-light);
    opacity: 0.25;
}

.projects {
    margin-bottom: 4em;
}

.projects {
    max-width: 750px;
    margin: 0 auto;
}

@media (min-width: 800px) {
    .services {
        display: flex;
        max-width: 1000px;
        margin-left: auto;
        margin-right: auto;
    }
    
    .service + .service {
        margin-left: 2em;
    }
}


.about-me {
    max-width: 1000px;
    margin: 0 auto;
}

.about-me__img {
    box-shadow: var(--bs);
}

@media (min-width: 600px) {
    .about-me {
        display: grid;
        grid-template-columns: 1fr 200px;
        grid-template-areas: 
            "title img"
            "subtitle img"
            "text img";
        grid-column-gap: 2em;
    }
    
    section__title--about-me {
        grid-area: title;
    }
    
    .section__title--about-me {
        grid-column: 1 / -1;
        grid-row: 2;
        position: relative;
        left: -1em;
        width: calc(100% + 2em);
        padding-left: 1em;
        padding-right: calc(200px + 4em);
    }
    
    .section__title {
        grid-area: img;
        position: relative;
        z-index: 2;
    }
}

/* My Work */

.my-work {
    background-color: var(--clr-dark);
    color: var(--clr-light);
    text-align: center;    
}

.portfolio {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.portfolio__item {
    background: var(--clr-accent);
    overflow: hidden;
}

.portfolio__img {
    transition: 
        transform 750ms cubic-bezier(.5, 0, .5, 1),
        opacity 250ms linear;
}

.portfolio__item:focus {
    position: relative;
    z-index: 2;
}

.portfolio__img:hover,
.portfolio__item:focus .portfolio__img {
    transform: scale(1.2);
    opacity: .5;
}


/* footer */

.footer {
    background: #111;
    color: var(--clr-accent);
    text-align: left;
    padding: 2.5em 0;
    font-size: var(--fs-h3);
    display: flex;
    justify-content: flex-start; /* This aligns content to the left */
  }
  
  .footer a {
    color: inherit;
    text-decoration: none;
    margin-right: 20px; /* Add margin for spacing between links */
  }

.footer__link {
    font-weight: var(--fw-bold);
}

.footer__link:hover,
.social-list__link:hover {
    opacity: .7;
}

.footer__link:hover {
    text-decoration: underline;
}

.social-list {
    list-style: none;
    display: flex;
    justify-content: center;
    margin: 2em 0 0;
    padding: 0;
}

.social-list__item {
    margin: 0 .5em;
}

.social-list__link {
    padding: .5em;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
